exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allasok-js": () => import("./../../../src/pages/allasok.js" /* webpackChunkName: "component---src-pages-allasok-js" */),
  "component---src-pages-etkezes-js": () => import("./../../../src/pages/etkezes.js" /* webpackChunkName: "component---src-pages-etkezes-js" */),
  "component---src-pages-fenntarto-js": () => import("./../../../src/pages/fenntarto.js" /* webpackChunkName: "component---src-pages-fenntarto-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-gimis-elet-js": () => import("./../../../src/pages/gimis-elet.js" /* webpackChunkName: "component---src-pages-gimis-elet-js" */),
  "component---src-pages-gimnazium-js": () => import("./../../../src/pages/gimnazium.js" /* webpackChunkName: "component---src-pages-gimnazium-js" */),
  "component---src-pages-gondolatok-js": () => import("./../../../src/pages/gondolatok.js" /* webpackChunkName: "component---src-pages-gondolatok-js" */),
  "component---src-pages-hirek-js": () => import("./../../../src/pages/hirek.js" /* webpackChunkName: "component---src-pages-hirek-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iskola-js": () => import("./../../../src/pages/iskola.js" /* webpackChunkName: "component---src-pages-iskola-js" */),
  "component---src-pages-jelentkezes-js": () => import("./../../../src/pages/jelentkezes.js" /* webpackChunkName: "component---src-pages-jelentkezes-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-podium-js": () => import("./../../../src/pages/podium.js" /* webpackChunkName: "component---src-pages-podium-js" */),
  "component---src-templates-eletkep-js": () => import("./../../../src/templates/eletkep.js" /* webpackChunkName: "component---src-templates-eletkep-js" */),
  "component---src-templates-eloadas-js": () => import("./../../../src/templates/eloadas.js" /* webpackChunkName: "component---src-templates-eloadas-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

